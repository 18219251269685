<template>
  <div class="responser-emails relative">
    <header class="responser-emails__header">
      <p class="responser-emails__title color_black">
        {{ t('inboxPage.chatSection.actions.newEmail') }}
      </p>
      <div
        v-if="!isEmailModal"
        class="responser-emails__nav-btn"
      >
        <default-button
          icon-name="bx-link-external"
          form="circle"
          icon-color="color_tertiary"
          size="s"
          @action="toggleModal"
        />
        <default-button
          icon-name="bx-x"
          color="neutral"
          icon-color="color_tertiary"
          form="circle"
          size="s"
          @action="emit('close')"
        />
      </div>
    </header>
    <div
      ref="vldParent"
      class="vld-parent"
      :class="{'responser-emails_bordered': route.name === 'my-prospects'}"
    >
      <generate-prompt-inbox
        v-if="isAiPromptShown"
        class="pl-2 pr-2 mb-2"
        :message="htmlParse(emailBody)"
        :profile-id="profile._id"
        :credentials-id="selectedCredential._id"
        request-id-suffix="#emails-responser"
        message-type="email"
        @update:generate="handleAiGeneratedPrompt"
      />

      <div class="px-2">
        <div class="flex-column flex-gap-8">
          <div class="responser-emails__settings">
            <p class="text_s color_tertiary">
              {{ t('inboxPage.chatSection.email.from') }}
            </p>
            <select-emails
              :class="{'responser-emails__email-from': selectedIntegration?.email}"
              :selected="selectedIntegration"
              :items="transformedIntegrations"
              @select="chooseIntegration($event)"
            />
          </div>
          <div class="responser-emails__settings-copy">
            <div class="responser-emails__settings">
              <p class="text_s color_tertiary">
                {{ t('inboxPage.chatSection.email.to') }}
              </p>
              <select-emails
                :class="{'responser-emails__email-to': emailToObj.email}"
                :selected="emailToObj"
                :items="emailsToList"
                @select="emailToObj = $event"
              />
            </div>
            <div class="responser-emails__copying">
              <p
                v-if="!isCcActive"
                class="responser-emails__copying-item text_xs"
                @click="isCcActive = true"
              >
                {{ t('inboxPage.chatSection.email.cc') }}
              </p>
              <p
                v-if="!isBccActive"
                class="responser-emails__copying-item text_xs"
                @click="isBccActive = true"
              >
                {{ t('inboxPage.chatSection.email.bcc') }}
              </p>
            </div>
          </div>
          <div
            v-if="isCcActive"
            class="responser-emails__settings"
          >
            <p class="text_s responser-emails__settings-item">
              {{ t('inboxPage.chatSection.email.cc') }}:
            </p>
            <fillable-input
              input-id="ccEmails"
              input-name="Cc Emails"
              input-size="s"
              input-placeholder="Add email"
              :is-need-submit-button="false"
              :label-type="''"
              :is-nude="true"
              :is-email="true"
              :items="ccEmails"
              @delete="deleteCcEmail($event)"
              @add="addCcEmail($event)"
            />
          </div>
          <div
            v-if="isBccActive"
            class="responser-emails__settings"
          >
            <p class="text_s responser-emails__settings-item">
              {{ t('inboxPage.chatSection.email.bcc') }}:
            </p>
            <fillable-input
              input-id="ccEmails"
              input-name="Cc Emails"
              input-placeholder="Add email"
              input-size="s"
              :label-type="''"
              :is-nude="true"
              :is-email="true"
              :is-need-submit-button="false"
              :items="bccEmails"
              @delete="deleteBccEmail($event)"
              @add="addBccEmail($event)"
            />
          </div>
          <div class="responser-emails__settings responser-emails__settings-subject">
            <p class="text_s color_tertiary">
              {{ t('inboxPage.chatSection.email.subject') }}
            </p>
            <default-input
              :value="emailSubject"
              size="s"
              class="responser-emails__container_none"
              @update:value="subjectChange($event)"
            />
          </div>
        </div>
        <rich-editor
          ref="richEditor"
          class="responser-emails__quill-textarea"
          editor-class="tw-min-h-40 tw-py-2"
          :content="emailBody"
          :editable="true"
          :selected-signature="selectedSignature"
          @update:selected-signature="selectedSignature = $event"
          @update:content="emailChange($event)"
        />
      </div>
      <div class="responser-emails__footer-emoji flex-row flex-justify-content-between">
        <buttons-responser
          ref="buttonsResponserRef"
          :has-responses-button="false"
          @open-emojies="isEmojiesPickerShown = !isEmojiesPickerShown"
          @open-generate="isAiPromptShown = !isAiPromptShown"
          @open-events="isEventsActive = !isEventsActive"
        />

        <default-button
          :title="t('actions.send')"
          color="primary"
          form="rectangle"
          size="s"
          :is-disabled="!isValidForm"
          class="responser-emails__footer-send"
          @action="sendEmailHandler"
        />
        <Picker
          v-show="isEmojiesPickerShown"
          ref="emojiesPickerRef"
          class="responser-emails__emojies"
          :data="emojiesData"
          :emoji-size="24"
          :native="true"
          set="native"
          :per-line="8"
          :show-preview="false"
          :show-skin-tones="false"
          :i18n="emojiPickerLocales"
          @select="insertEmoji"
        />

        <event-types
          v-if="isEventsActive"
          ref="eventTypesRef"
          class="responser__events"
          :step="2"
          :link-params="eventTypesLinkParams"
          @insert-link="insertEventLink"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onBeforeMount, onMounted } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import SelectEmails from '@/components/inboxPage/chat/emails/responser/SelectEmails';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import ButtonsResponser from '@/components/inboxPage/chat/messages/responser/ButtonsResponser.vue';
  import GeneratePromptInbox from '@/components/base/GeneratePrompt/GeneratePromptInbox.vue';
  import EventTypes from '@/components/inboxPage/chat/events/EventTypes.vue';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/all.json';
  import { sendEmail } from '@/api/profilesMethods';
  import { generateEmojiPickerLocale } from '@/utils/translation/utils';
  import { useStore } from '@/store';
  import { useLoader, loadingWrapper } from '@/use/loader';
  import { useI18n } from '@/i18n';
  import { useRoute } from 'vue-router/composables';
  import { useClosable, ClosableData } from '@/use/useClosable';
  import RichEditor from '@/components/richEditor/RichEditor.vue';
  import FillableInput from '@/components/base/FillableInput.vue';
  import { DraftMessage } from '@/components/inboxPage/chat/utils/draftMessage';
  import { MessageTypes } from '@/types/messages';
  import { htmlParse } from '@/utils/externalScripts/htmlParser';

  const props = defineProps({
    lastEmail: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      required: true,
    },
    isEmailModal: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(['send-email', 'close', 'update-is-email-modal']);

  const { t } = useI18n();

  const route = useRoute();

  const store = useStore();
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  const integrationsList = computed(() => store.getters['integrations/integrationsList']);

  const buttonsResponserRef = ref(null);
  const richEditor = ref(null);

  const emailsToList = computed(() => {
    const result = [
      {
        icon: 'MailIcon',
        email: props.profile.email,
      },
      {
        icon: 'MailIcon',
        email: props.profile.work_email,
      },
      {
        icon: 'MailIcon',
        email: props.profile.enriched_data.enriched_emails?.at(-1)?.email,
      },
    ];
    return result.filter(i => i.email);
  });

  const isValidForm = computed(() => {
    return emailBody.value && emailSubject.value && selectedIntegration.value?._id;
  });

  // #region Initialization
  onBeforeMount(init);

  watch(() => props.lastEmail, init);

  function init () {
    emailBody.value = draft.value?.draft || '';
    emailSubject.value = draft.value?.subject || '';
    selectedIntegration.value = getSelectedIntegration();
    emailToObj.value = emailsToList.value.find(i => i.email === props.lastEmail?.sender) || emailsToList.value[0] || {};
  }
  // #endregion

  // #region Loader
  const isLoading = ref(false);
  const vldParent = ref(null);

  const { loaderWatcher } = useLoader({ container: vldParent });
  loaderWatcher(isLoading);
  // #endregion

  // #region Closable
  onMounted(() => {
    const excludedEmojiesToggler = ref(buttonsResponserRef.value.$refs.emojiesPickerToggler);
    useClosable(new ClosableData(
      emojiesPickerRef,
      excludedEmojiesToggler,
      isEmojiesPickerShown
    ));
  });
  // #endregion

  // #region Emojies
  const emojiesData = new EmojiIndex(data);

  const emojiesPickerRef = ref(null);
  const isEmojiesPickerShown = ref(false);

  const emojiPickerLocales = computed(() => {
    return generateEmojiPickerLocale();
  });

  function insertEmoji (emoji) {
    richEditor.value.editor.chain().insertContent(emoji.native).focus().run();
    isEmojiesPickerShown.value = false;
  }
  // #endregion

  // #region Event Types
  const eventTypesRef = ref(null);
  const isEventsActive = ref(false);

  const eventTypesLinkParams = { utm_content: String(props.profile._id) };

  const insertEventLink = (url) => {
    richEditor.value.editor.chain().insertContent(url).focus().run();
  };
  // #endregion

  // #region Integrations
  const selectedIntegration = ref({});

  const transformedIntegrations = computed(() => {
    return integrationsList.value
      .filter(i => i.status === 'connected')
      .map(i => ({
        icon: getIconName(i.type),
        _id: i._id,
        email: i.email,
        ccEmails: i.bcc_emails,
        bccEmails: i.cc_emails,
      }));
  });

  function chooseIntegration (integration) {
    selectedIntegration.value = integration;
    setCopyEmails();
  }
  function getSelectedIntegration () {
    const usedIntegration = transformedIntegrations.value.find(i =>
      i.type === props.lastEmail?.provider && i.email === props.lastEmail?.receiver
    );
    if (usedIntegration) {
      return {
        icon: getIconName(props.lastEmail.provider),
        _id: usedIntegration._id,
        email: props.lastEmail.receiver,
        ccEmails: usedIntegration.bcc_emails,
        bccEmails: usedIntegration.cc_emails,
      };
    }
    return transformedIntegrations.value[0];
  }
  // #endregion

  // #region signature
  const selectedSignature = ref(null);
  // #endregion

  // #region AI prompt
  const isAiPromptShown = ref(false);

  function handleAiGeneratedPrompt (generatedMessage) {
    richEditor.value.editor.chain().setContent(generatedMessage.replaceAll('\n', '<br>')).focus().run();
    emailChange(generatedMessage);
  }
  // #endregion

  // #region Email content
  const emailToObj = ref({});
  const emailSubject = ref('');
  const emailBody = ref('');

  function emailChange (htmlEmail) {
    emailBody.value = htmlEmail.trim();
    const draftMessage = new DraftMessage(props.profile._id, emailBody.value, MessageTypes.EMAIL, emailSubject.value);
    store.commit('profile/UPDATE_DRAFT_MESSAGE', draftMessage);
  }

  function subjectChange (subject) {
    emailSubject.value = subject;
    const draftMessage = new DraftMessage(props.profile._id, emailBody.value, MessageTypes.EMAIL, emailSubject.value);
    store.commit('profile/UPDATE_DRAFT_MESSAGE', draftMessage);
  }

  const sendEmailHandler = loadingWrapper(_sendEmailHandler, isLoading);

  async function _sendEmailHandler () {
    emailBody.value = emailBody.value.replace(/<p>\s*<br>\s*<\/p>/g, '<span><br></span>');
    const email = await sendEmail(
      props.profile._id,
      selectedCredential.value._id,
      emailToObj.value.email,
      selectedIntegration.value._id,
      emailSubject.value,
      emailBody.value,
      ccEmails.value,
      bccEmails.value,
      selectedSignature.value?._id || ''
    );
    emailSubject.value = '';
    emailChange('');
    emit('send-email', email);
  }
  // #endregion

  // #region cc bcc emails
  const isCcActive = ref(false);
  const isBccActive = ref(false);
  const ccEmails = ref([]);
  const bccEmails = ref([]);

  const setCopyEmails = () => {
    ccEmails.value = selectedIntegration.value.ccEmails;
    bccEmails.value = selectedIntegration.value.bccEmails;
  };

  function addCcEmail (email) {
    const isExist = ccEmails.value.some(ccEmail => email === ccEmail);
    if (!isExist) {
      ccEmails.value.push(email);
    }
  }
  function deleteCcEmail (email) {
    ccEmails.value = ccEmails.value.filter(ccEmail => ccEmail !== email);
  }
  function addBccEmail (email) {
    const isExist = bccEmails.value.some(bccEmail => email === bccEmail);
    if (!isExist) {
      bccEmails.value.push(email);
    }
  }
  function deleteBccEmail (email) {
    bccEmails.value = bccEmails.value.filter(bccEmail => bccEmail !== email);
  }
  // #endregion

  // #region draft message
  const draftMessages = computed(() => store.getters['profile/draftMessages']);
  const draft = computed(() => {
    const draft = draftMessages.value[props.profile._id];
    return draft?.email || null;
  });
  // #endregion

  // #region Helpers
  function getIconName (integrationType) {
    switch (integrationType) {
    case 'smtp':
      return 'MailIcon';
    case 'google':
      return 'GmailIcon';
    case 'microsoft':
      return 'OutlookIcon';
    case 'sendgrid':
      return 'SendGridIcon';
    case 'exchange':
      return 'ExchangeIcon';
    case 'amazon':
      return 'AwsIcon';
    }
  }

  function toggleModal () {
    emit('close');
    emit('update:is-email-modal', !props.isEmailModal);
  }
// #endregion
</script>

<style lang="scss">
.responser-emails {
  border-radius: 8px;
  background: var(--background-color);
  position: relative;
  &__emojies {
    position: absolute;
    left: 24px;
    bottom: 52px;
  }
  &__footer-emoji {
    position: relative;
  }
  &__container_none {
    .ui-kit-input__container {
      outline: none;
    }
  }

  &__email {
    &-from {
      background: rgba(31, 119, 242, 0.06);
      padding: 2px 6px;
      border-radius: 4px;
    }

    &-to {
      padding: 2px 6px;
      border-radius: 4px;
      background: var(--color-surface-50);
    }
  }

  &__blockSignature {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      width: 100%;
    }
  }

  &__add {
    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-signature {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &_text {
        color: var(--icon-subdued);
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.01px;
      }

      &_switch {
        border-radius: 4px;
        margin: 8px 0;
        background: var(--surface-200);
        align-self: flex-end;

        .uikit-tooltip__list {
          right: 100%;
          top: -200%;
        }
      }
    }
  }

  &_bordered {
    border: 1px solid var(--neutral-200);
    border-radius: 0 0 8px 8px;
    border-top: none;
  }

  &__header {
    @include flex-row-between;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background: var(--color-text-on-interactive);
  }

  &__title {
    @include heading_l;
  }

  &__copying {
    display: flex;
    align-items: center;
    gap: 6px;

    &-item {
      cursor: pointer;
      padding: 4px 8px;
      color: var(--icon-subdued);
    }
  }

  &__settings {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6px;
    align-items: center;
    justify-content: start;

    &-item {
      color: var(--icon-subdued);
      letter-spacing: -0.09px;
    }

    &-copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-subject {
      grid-template-columns: auto 1fr;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        height: 1px;
        margin: 0 -16px;
        background-color: var(--divider);
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 1px;
        margin: 0 -16px;
        background-color: var(--divider);
      }
    }
  }

  &__quill-textarea {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ql-container {
      height: 144px;
    }

    &_disable {
      max-height: 120px;
      min-height: 120px;
      justify-content: flex-start;
      font-size: 13px;

      .ql-toolbar {
        display: none;
      }
    }
  }

  &__footer{
    &-emoji {
      padding: 16px 16px;
    }
    &-send {
      width: 59px;
      height: 32px;
    }
  }

  &__nav-btn {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
